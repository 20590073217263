<template>
    <div>
        <div v-if="isLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
            <h6 class="text-body mt-3">Loading</h6>

        </div>
        <div v-if="!isLoading && errorLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-icon-exclamation-triangle class="h3" variant="danger"></b-icon-exclamation-triangle>
            <h6 class="text-danger mt-3">Ooops, there has been an error</h6>

        </div>
        <div v-if="!isLoading && !errorLoading">
            <h5 class="border-bottom pb-2 text-primary">{{ user.id !== undefined ? "Edit" : "Create"}} user <span
                    v-if="organisationName != null">({{ organisationName }})</span></h5>
            <form autocomplete="off">
                <label style="font-size: 13px;" class="mb-1" v-if="emailEditable() !== true">Currently editing:</label>
                <div class="mb-3 font-weight-bold p-2 bg-light rounded border" v-if="emailEditable() !== true">
                    {{ user.name }} : {{ user.email }}
                </div>
                <div class="row">
                    <div class="col-6">
                        <b-form-group
                                v-if="emailEditable()"
                                label-size="sm"
                                label="First name:">
                            <b-form-input size="sm" v-model="user.first_name" placeholder="User first name"/>
                            <error-display v-model="errors" ident="first_name"></error-display>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group
                                v-if="emailEditable()"
                                label-size="sm"
                                label="Last name:">
                            <b-form-input size="sm" v-model="user.last_name" placeholder="User last name"/>
                            <error-display v-model="errors" ident="last_name"></error-display>
                        </b-form-group>
                    </div>
                </div>

                <b-form-group
                        v-if="emailEditable()"
                        label-size="sm"
                        label="Email:">
                    <b-form-input size="sm" v-model="user.email" placeholder="Email address"/>
                    <error-display v-model="errors" ident="email"></error-display>
                    <div v-if="originalUser.id != null && originalUser.email !== user.email"
                         class="alert alert-danger mt-2">
                        Editing this email will require the user to validate the new email before they are
                        able to login
                    </div>
                </b-form-group>

                <b-form-group
                        label-size="sm"
                        label="Select user type:">
                    <b-select size="sm" v-model="user.user_type" :options="['supervisor','user']"></b-select>
                    <error-display v-model="errors" ident="user_type"></error-display>
                </b-form-group>

                <b-form-group v-if="user.user_type === 'user'"
                              label-size="sm"
                              label="Supervisors for this user:">
                    <div v-if="isLoadingUsers" class="d-flex align-items-center border p-2 rounded">
                        <b-spinner small variant="primary" class="mr-2"></b-spinner>
                        Loading users
                    </div>
                    <div v-else>
                        <v-select
                                ref="supervisorSelect"
                                label="name"
                                v-model="user.supervisor_ids"
                                :multiple="true"
                                :reduce="option => option.value"
                                :options="supervisors"
                                :filterable="true"
                                placeholder="Search for supervisor">
                        </v-select>
                        <div class="mt-2 text-right">
                            <b-button :pill="true" variant="secondary" size="sm" @click="deselectSupervisor">Deselect
                                all
                            </b-button>
                            <b-button :pill="true" variant="primary" class="ml-1" size="sm"
                                      @click="selectAllSupervisors">Select all
                            </b-button>
                        </div>
                    </div>

                    <error-display v-model="errors" ident="supervisor_ids"></error-display>
                </b-form-group>

                <b-form-group
                        v-if="user.user_type === 'supervisor'"
                        label-size="sm"
                        label="Users for this supervisor:">
                    <div v-if="isLoadingUsers" class="d-flex align-items-center border p-2 rounded">
                        <b-spinner small variant="primary" class="mr-2"></b-spinner>
                        Loading users
                    </div>
                    <div v-else>
                        <v-select
                                ref="userSelect"
                                label="name"
                                v-model="user.user_ids"
                                :multiple="true"
                                :reduce="option => option.value"
                                :options="users"
                                :filterable="true"
                                placeholder="Search for user">
                        </v-select>
                        <div class="mt-2 text-right">
                            <b-button :pill="true" variant="secondary" size="sm" @click="deselectUser">Deselect
                                all
                            </b-button>
                            <b-button :pill="true" variant="primary" class="ml-1" size="sm"
                                      @click="selectAllUsers">Select all
                            </b-button>
                        </div>
                    </div>
                    <error-display v-model="errors" ident="user_ids"></error-display>
                </b-form-group>

                <b-form-group v-if="user.user_type === 'supervisor'">
                    <b-form-checkbox
                            v-model="user.is_admin"
                            value="1"
                            unchecked-value="0"
                    >
                        Admin access
                    </b-form-checkbox>
                    <error-display v-model="errors" ident="is_admin"></error-display>
                </b-form-group>

                <b-form-group>
                    <b-form-checkbox
                            v-model="user.billing"
                            value="1"
                            unchecked-value="0"
                    >
                        <div class="font-weight-bold">
                            Billing access
                        </div>
                        <div class="text-muted font-weight-light" style="font-size: 14px;">
                            (user can manage billing info and view transactional data)
                        </div>
                    </b-form-checkbox>
                    <error-display v-model="errors" ident="is_admin"></error-display>
                </b-form-group>

                <b-form-group>
                    <b-form-checkbox
                            v-model="user.app_access"
                            value="1"
                            unchecked-value="0"
                    >
                        <div class="font-weight-bold">
                            Application access
                        </div>
                        <div class="text-muted font-weight-light" style="font-size: 14px;">
                            (user can access the web and mobile apps)
                        </div>
                    </b-form-checkbox>
                    <error-display v-model="errors" ident="is_admin"></error-display>
                </b-form-group>

                <b-form-group v-if="this.$store.getters.isSuper">
                    <b-form-checkbox
                            v-model="user.is_super"
                            value="1"
                            unchecked-value="0"
                    >
                        Super admin access
                    </b-form-checkbox>
                    <error-display v-model="errors" ident="is_super"></error-display>
                </b-form-group>

                <div class="text-right">
                    <b-button @click="saveUser" variant="success">Save</b-button>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
    import {getResource, saveResource} from "../../modules/api/methods";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import _ from 'lodash'
    import {baseApi, organisationSetupUsers, userDetail, userSave, usersDropdown} from "../../modules/api/endpoints";
    import vSelect from "vue-select"
    import {containsErrors} from "../../modules/helpers/helpers";

    export default {
        name: "UserEdit",
        components: {ErrorDisplay, "v-select": vSelect,},
        props: {
            userId: null,
            organisationId: null,
            organisationName: null,
            fromSetup : false,
        },
        data: function () {
            return {
                errorLoading: false,
                isLoading: false,
                isLoadingUsers: false,
                user: {},
                originalUser: {},
                errors: {},
                apiEndpoint: baseApi() + 'users/dropdown',
                supervisors: [],
                users: []
            }
        },
        created() {
            if (this.userId != null) {
                this.loadUser();
            } else {
                this.loadAllUsers();
                this.user.app_access = 1;
                if (this.organisationId != null) {
                    this.user.organisation_id = this.organisationId;
                }
            }
        },

        methods: {
            emailEditable() {
                if (this.originalUser != null && this.originalUser.organisation != null && this.originalUser.workspace_user_id != null) {
                    return false;
                }
                return true;
            },
            userType(type) {
                return [{key: 'roles', value: type}];
            },
            selectAllSupervisors() {
                var user = this.user;
                user.supervisor_ids = _.map(this.supervisors, 'value');
                this.user = user;
                const select = this.$refs.supervisorSelect;
                select.options.forEach(option => {
                    select.select(option);
                });
                select.open = false
            },
            deselectSupervisor() {
                var user = this.user;
                user.supervisor_ids = [];
                this.user = user;
                const select = this.$refs.supervisorSelect;
                select.options.forEach(option => {
                    select.deselect(option);
                });
                select.open = false
            },
            selectAllUsers() {
                var user = this.user;
                user.user_ids = _.map(this.users, 'value');
                this.user = user;
                const select = this.$refs.userSelect;
                select.options.forEach(option => {
                    select.select(option);
                });
                select.open = false
            },
            deselectUser() {
                var user = this.user;
                user.user_ids = [];
                this.user = user;
                const select = this.$refs.userSelect;
                select.options.forEach(option => {
                    select.deselect(option);
                });
                select.open = false
            },
            loadAllUsers() {
                this.isLoadingUsers = true;
                var vm = this;
                saveResource(usersDropdown, {organisation_id: this.user.organisation_id}).then((resp) => {

                    this.users = resp.data.success.filter(function (obj) {
                        if(vm.user.id !== undefined) {
                            return obj.user_type === 'user' && obj.value !== vm.user.id
                        } else {
                            return obj.user_type === 'user'
                        }
                    });
                    this.supervisors = resp.data.success.filter(function (obj) {
                        if(vm.user.id !== undefined) {
                            return obj.user_type === 'supervisor' && obj.value !== vm.user.id
                        } else {
                            return obj.user_type === 'supervisor'
                        }
                    });

                    if (vm.user.user_ids !== undefined) {
                        var newUserIds = [];
                        _.each(vm.user.user_ids, function (id) {
                            if (_.filter(vm.users, function (user) {
                                return user.value === id;
                            }).length > 0) {
                                newUserIds.push(id);
                            }
                        });
                        vm.user.user_ids = newUserIds;
                    }
                    if (vm.user.supervisor_ids !== undefined) {
                        var newSupervisorIds = [];
                        _.each(vm.user.supervisor_ids, function (id) {
                            if (_.filter(vm.supervisors, function (user) {
                                return user.value === id;
                            }).length > 0) {
                                newSupervisorIds.push(id);
                            }
                        });
                        vm.user.supervisor_ids = newSupervisorIds;
                    }

                }).catch((ex) => {
                    this.$root.$children[0].handleApiError(ex, this.loadAllUsers);
                }).finally(() => {
                    this.isLoadingUsers = false;
                });
            },
            loadUser() {
                this.isLoading = true;
                getResource(userDetail(this.userId)).then((resp) => {
                    var user = resp.data.success.data;

                    if (user.roles !== undefined && user.roles.length > 0) {
                        _.each(user.roles, function (obj) {
                            console.log("role", obj);
                            if (obj.super !== undefined && obj.super === 1) {
                                user.is_super = 1;
                            }
                            if (obj.admin !== undefined && obj.admin === 1) {
                                user.is_admin = 1;
                            }
                            if (obj.app_access !== undefined && obj.app_access === 1) {
                                user.app_access = 1;
                            }
                            if (obj.billing !== undefined && obj.billing === 1) {
                                user.billing = 1;
                            }
                        });
                    }
                    if (user.users.length > 0) {
                        var userIds = [];
                        _.each(user.users, function (obj) {
                            userIds.push(obj.id)
                        });
                        user.user_ids = userIds;
                    }
                    if (user.supervisors.length > 0) {
                        var supervisorIds = [];
                        _.each(user.supervisors, function (obj) {
                            supervisorIds.push(obj.id);
                        });
                        user.supervisor_ids = supervisorIds;
                    }
                    this.user = user;
                    this.originalUser = _.clone(user);
                    console.log("USER IS ", user);
                    this.isLoading = false;
                    this.errorLoading = false;
                    this.loadAllUsers();
                }).catch((ex) => {
                    this.$root.$children[0].handleApiError(ex, this.loadUser);
                    this.isLoading = false;
                    this.errorLoading = true;
                });
            },
            saveUser() {
                this.isLoading = true;
                this.errors = {};
                saveResource((this.$store.getters.isOrgSetup ? userSave : organisationSetupUsers), this.user).then((resp) => {
                    this.isLoading = false;
                    if (this.$store.getters.isOrgSetup) {
                        if (this.organisationId != null) {
                            this.$router.push({'name': 'organisations', params: {data_updated: true}}).catch(error => {
                            });
                        } else {
                            this.$router.push({
                                name: (this.$router.currentRoute.name.indexOf('Admin') !== -1 ? 'usersAdmin' : 'users'),
                                params: {data_updated: true}
                            }).catch(error => {
                            });
                        }
                    } else {
                        this.$router.push({
                            name: ('setup_users_setup'),
                            params: {data_updated: true}
                        }).catch(error => {
                        });
                    }
                }).catch((err) => {
                    console.log("ERROR IS", err);
                    if (containsErrors(err)) {
                        this.errors = err.data.errors;
                    }
                    this.isLoading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
